<template>
  <div>
    <PaidFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div>
      <div class="minHieght100 main-content mt-5">
        <div v-if="!isLoading && isEmpty(studentReceivedFees)">
          <NoRecordFound />
        </div>
        <TableWrapper v-else-if="!isLoading && !isEmpty(studentReceivedFees)">
          <THead>
            <TRHead>
              <TH
                v-for="(head, index) in tableHeaders"
                v-show="showColumnInDataTable(filtersData, head.dataTableName, ['Student Name'])"
                :key="index"
              >
                <span v-i18n="fees">
                  {{ head.dataTableName }}
                </span>
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(fee, index) in studentReceivedFees" :key="index">
              <TD>
                <SingleUserDisplay
                  :required-items="1"
                  :user="fee.student"
                  label="full_name"
                  :image="true"
                />
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Fee Title')">
                {{
                  (fee.section_fee && fee.section_fee.title) ||
                  fee.title ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Fee Type')">
                {{ fee.fee_type.title }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Total Fee')">
                {{ fee.fee_amount }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Paid')">
                {{ fee.paid_amount }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Payable')">
                {{ fee.payable_amount }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Discount')">
                {{ fee.discount_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Fine')">
                {{ fee.fine_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Currency')">
                <span class="uppercase">
                  {{ fee.currency || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                </span>
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Received By')">
                <span v-for="(user, ind) in fee.student_fee_receivings" :key="ind">
                  <span v-if="fee.student_fee_receivings.length < 2 && fee.payable_amount === 0">
                    {{ user.received_by.first_name }}
                    {{ user.received_by.last_name }}
                  </span>
                  <span v-else>
                    {{ user.received_by.first_name }}
                    {{ user.received_by.last_name }} Received Installment No
                    {{ user.collection_no }}
                  </span>
                </span>
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Received On')">
                <span v-if="fee.student_fee_receivings[0]">
                  {{ formatDate(fee.student_fee_receivings[0].date) }}
                </span>
                <span v-else>
                  {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </span>
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Due Date')">
                {{ formatDate(fee.due_date) }}
              </TD>
              <TD v-show="showColumnInDataTable(filtersData, 'Status')">
                <PaymentStatus :status="fee.status" />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
        <div v-else class="mt-28">
          <Loader :content="true" />
        </div>
        <Pagination
          v-if="showPagination"
          v-show="!isLoading"
          :record-limit="filteredRecordLimit"
          :max-range="paginationCounts(studentReceivedFeesCounts, filteredRecordLimit)"
          @filterRecord="filterRecord"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import PaymentStatus from '@/src/router/views/finance/fees/feeActionsModals/PaymentStatus.vue'
import {
  showColumnInDataTable,
  removeEmptyKeysFromObject,
  objectDeepCopy,
} from '@utils/generalUtil'
import fileMixin from '@src/mixins/file-mixins'
import PaidFilters from '@components/fee/students/PaidFilters.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    Pagination,
    Loader,
    NoRecordFound,
    SingleUserDisplay,
    PaymentStatus,
    PaidFilters,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, scrollMixin, fileMixin],

  data() {
    return {
      filtersData: {},
      alwaysDisplayColumnsForCSV: {
        'student.first_name': 'First Name',
        'student.last_name': 'Last Name',
      },
      defaultSelectedColumns: [
        'Fee Title',
        'Fee Type',
        'Total Fee',
        'Paid',
        'Payable',
        'Currency',
        'Due Date',
        'Status',
      ],
      tableHeaders: [
        {
          id: '0',
          dataTableName: 'Student Name',
          backendKeyForCSV: 'Student Name',
          readableValueForCSV: 'Student Name',
          skipForFilterOptions: true,
        },
        {
          id: '1',
          dataTableName: 'Fee Title',
          backendKeyForCSV: 'section_fee.title',
          readableValueForCSV: 'Fee Title',
        },
        {
          id: '2',
          dataTableName: 'Fee Type',
          backendKeyForCSV: 'fee_type.title',
          readableValueForCSV: 'Fee Type',
        },
        {
          id: '3',
          dataTableName: 'Total Fee',
          backendKeyForCSV: 'fee_amount',
          readableValueForCSV: 'Total Fee',
        },
        {
          id: '4',
          dataTableName: 'Paid',
          backendKeyForCSV: 'paid_amount',
          readableValueForCSV: 'Paid',
        },
        {
          id: '5',
          dataTableName: 'Payable',
          backendKeyForCSV: 'payable_amount',
          readableValueForCSV: 'Payable',
        },
        {
          id: '6',
          dataTableName: 'Discount',
          backendKeyForCSV: 'discount_amount',
          readableValueForCSV: 'Discount',
        },
        {
          id: '7',
          dataTableName: 'Fine',
          backendKeyForCSV: 'fine_amount',
          readableValueForCSV: 'Fine',
        },
        {
          id: '8',
          dataTableName: 'Currency',
          backendKeyForCSV: 'currency',
          readableValueForCSV: 'Currency',
        },
        {
          id: '9',
          dataTableName: 'Received By',
          backendKeyForCSV: 'student_fee_receivings.received_by',
          readableValueForCSV: 'Received By',
        },
        {
          id: '10',
          dataTableName: 'Received On',
          backendKeyForCSV: 'paid_date',
          readableValueForCSV: 'Received On',
        },
        {
          id: '11',
          dataTableName: 'Due Date',
          backendKeyForCSV: 'due_date',
          readableValueForCSV: 'Due Date',
        },
        {
          id: '12',
          dataTableName: 'Status',
          backendKeyForCSV: 'status',
          readableValueForCSV: 'Status',
        },
      ],
      fees: 'fees',
      dashboard: 'dashboard',
      invoiceModal: false,
      actionList: [{ name: 'Delete' }],
      filteredRecordLimit: 10,
      isLoading: false,
      currentUserPosition: '',
      currentUser: {},
      showPagination: false,
      GENERAL_CONSTANTS,
    }
  },

  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      showUiModal: (state) => state.layout.showUiModal,
      studentReceivedFees: (state) => state.student.studentReceivedFees?.records || [],
      studentReceivedFeesCounts: (state) => state.student.studentReceivedFees.meta?.total_records,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.filterRecord()
      },
    },
  },
  /**
   * Mounted hook
   * @description Calling FilterRecord method
   */
  mounted() {
    this.setRightBarData()
    this.filterRecord()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent', 'setShowModal', 'setShowUiModal']),
    ...mapActions('fee', ['deleteStudentFee']),
    ...mapActions('student', ['getStudentsReceivedFees']),

    isEmpty,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      removeEmptyKeysFromObject(filtersPayload)
      this.filtersData = objectDeepCopy(filtersPayload)
    },
    /**
     * Filter Record
     * @param {number} range
     * @returns {void}
     * @description Method description
     * -  Filter the record on the basis of range
     * -  Setting right bar data
     */
    filterRecord(range) {
      this.isLoading = true
      this.getStudentsReceivedFees({
        page_type: 'received',
        skip: range?.skip || 0,
        limit: range?.limit || 10,
      }).then(() => {
        this.isLoading = false
        this.showPagination = this.studentReceivedFeesCounts > 10
        this.setRightBarData()
      })
    },
    /**
     * Set Right Bar Data
     * @description Set RightBar data after API response with real stats
     */
    setRightBarData() {
      const student_id = JSON.parse(localStorage.getItem('currentActiveStudent'))?.id || '0'
      const rightBarContent = {
        header: {
          title: 'Fees',
          buttons: [
            {
              title: 'DOWNLOAD_CSV',
              icon: 'download',
              iconHeight: '18',
              iconWidth: '18',
              classes: ['primary-button-right'],
              action: {
                func: () => this.downloadCSV('studentPaid', { student_id }),
              },
              permissions: {
                basedOn: ['section-level'],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'PAID', value: this.studentReceivedFeesCounts || 0 }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    /**
     * Format Date
     * @param {string} data
     * @returns Formatted due date
     * @description Formate the due date
     */
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and setShowUiModal method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          this.setShowUiModal(true)
          break
        case 'close':
          this.setShowModal(false)
          this.setShowUiModal(false)
          break
        case 'cancel':
          this.setShowModal(false)
          this.setShowUiModal(false)
          break
        case 'confirm':
          break
      }
    },
    /**
     * Fee Action
     * @param {string} action
     * @param {number} currentStudent
     *  @param {number} idx - Index of current student
     * @returns {void}
     * @description Delete fee on the basis of action
     */
    feeAction(action, currentStudent, idx) {
      this.currentUserPosition = idx
      this.currentUser = currentStudent
      switch (action) {
        case 'Delete':
          this.deleteFee(currentStudent.id, idx)
          break
      }
    },
    /**
     * Delete Fee
     * @param {number} id - Current id of fee
     * @param {number} idx - Current index of fee id
     * @returns {void}
     * @description Method description
     * -  Deleting fee on the basis of id
     * -  Setting right bar data
     */
    deleteFee(id, idx) {
      this.deleteStudentFee(id).then(() => {
        this.$store.commit('fee/DELETE_RECEIVED_STUDENT_FEE', idx)
        this.setRightBarData()
      })
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<template>
  <!-- <ValidationObserver v-slot="{ invalid }" tag="div"> -->
  <TitleWrapper
    :filter-option="true"
    title="PAID"
    tooltip-title="PAID_TOOLTIP"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    @applyFilter="applyFilters($event, invalid)"
  >
    <template v-slot:filterItems>
      <div class="mb-5 text-text-color font-roboto font-normal text-lg">Search</div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
        <UiSingleSelect
          title="Class"
          :options="options"
          :class="$style.widthItems"
          class="flex-1"
        />
        <UiSingleSelect
          title="Section"
          :options="options"
          :class="$style.widthItems"
          class="flex-1"
        />
        <UiSingleSelect
          title="Student Id"
          :options="options"
          :class="$style.widthItems"
          class="flex-1"
        />
      </div>
      <div class="grid gap-5 grid-cols-1 md:grid-cols-3 mt-5">
        <UiSingleSelect
          title="Student Name"
          :options="options"
          :class="$style.widthItems"
          class="flex-1"
        />
      </div>
    </template>
  </TitleWrapper>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'

export default {
  components: {
    TitleWrapper,
    UiSingleSelect,
    // ValidationObserver,
  },
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['apply'],
  data() {
    return {
      options: ['Select'],
      filtersPayload: {
        displayedColumns: [],
      },
    }
  },
  mounted() {
    if (this.alreadySelectedDisplayedColumns && this.alreadySelectedDisplayedColumns.length) {
      this.filtersPayload.displayedColumns = this.alreadySelectedDisplayedColumns
    }
  },
  methods: {
    applyFilters(event, invalid) {
      this.filtersPayload.displayedColumns = event.displayedColumns
      if (invalid) return
      this.$emit('apply', this.filtersPayload)
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
